import {BrowserRouter, Route, Routes } from 'react-router-dom'

import {
  HomePage,
  ServicesPage,
  ProductsPage,
} from './pages'

import { Analytics } from '@vercel/analytics/react';

function AppRouter() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/servicos" exact element={<ServicesPage />} />
        <Route path="/produtos" exact element={<ProductsPage />} />
      </Routes>
    </BrowserRouter>
    <Analytics />
    </>
  );
}

export default AppRouter;
