import React from "react";

import {
  Nav,
  Main,
  AboutUs2,
  Services,
  Products,
  Courses,
  Footer,
} from "../components";

function HomePage() {
  return (
    <>
      <Nav />
      <Main />
      <AboutUs2 />
      <Services />
      <Products />
      {/* <Courses /> */}
      <Footer />
    </>
  );
}

export { HomePage };
export default { HomePage };
