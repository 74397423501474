import React from "react";
import styled from "styled-components";
import { Vortex } from "react-loader-spinner";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 82px 280px;
  background-color: #272424;
  color: #fefefe;

  @media only screen and (max-width: 600px) {
    padding: 82px 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const TitleServices = styled.h3`
  font-size: 64px;
  margin: 0px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-family: Montserrat-extrabold;
`;

const LoadingContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const Paragraf = styled.p`
  margin-top: 0;
  width: 98%;
  text-align: center;
  font-size: 24px;
`;

function Courses() {
  return (
    <Container>
      <TitleServices>Aprenda Conosco</TitleServices>
      <LoadingContainer>
        <Vortex
          visible={true}
          height="150"
          width="150"
          ariaLabel="vortex-loading"
          wrapperStyle={{}}
          wrapperClass="vortex-wrapper"
          colors={[
            "#fa951a",
            "#fa951a",
            "#fa951a",
            "#fa951a",
            "#fa951a",
            "#fa951a",
          ]}
        />
      </LoadingContainer>

      <Paragraf>
        Aguarde, estamos preparando nossa BarberAcademy com uma formação
        completa para você se tornar um barbeiro de excelência.
      </Paragraf>
    </Container>
  );
}

export { Courses };
export default Courses;
