import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

import logoImg from "../assets/images/logo.svg";

const StyledNav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 6;
  height: 14vh;
  /* margin: 24px; */
  /* width: 100%; */
  background-color: #272424;
  display: flex;
  justify-content: space-evenly;
  /* padding: 0 170px; */
  /* border-bottom: 1px solid #fa951a; */
  box-shadow: 0px 10px 33px -14px rgba(0, 0, 0, 0.65);

  @media only screen and (max-width: 600px) {
    height: 8vh;
    padding: 0 14px;
  }

  /* @media only screen and (min-width: 1600px) {
    padding: 0 250px;
  } */
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 2%; */
`;

const Logo = styled.img`
  height: 70px;
  width: 95px;
  /* margin-bottom: 16px; */

  @media only screen and (max-width: 600px) {
    height: 55px;
    width: 70px;
  }

  @media only screen and (min-width: 1600px) {
    height: 120px;
    width: 150px;
  }
`;

const StyledLink = styled.a`
  margin: 8px;
  color: #fa951a;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  padding: 0 8px;
  transition: 100ms;
  /* text-transform: uppercase; */

  :hover {
    color: #fa951a;
    padding-bottom: 1px;
    border-bottom: 2px solid #fcbe73;
  }

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    margin: 0;
  }

  @media only screen and (min-width: 1600px) {
    font-size: 18px;
  }
`;

function Nav() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <StyledNav>
      <Container>
        <a href="/">
          <Logo src={logoImg} alt="Logo Very Clean" />
        </a>
      </Container>

      <Container>
        <StyledLink href="/">Início</StyledLink>
        <StyledLink href="/servicos">Serviços</StyledLink>
        <StyledLink href="/produtos" offset={-45}>
          Produtos
        </StyledLink>
      </Container>

      <Container></Container>
    </StyledNav>
  );
}

export { Nav };
export default { Nav };
