import React from 'react'

import { 
  Nav,
  ServicesPageSection,
  Footer,

 } from '../components'

function ServicesPage(){
  return (
    <>
      <Nav/>
      <ServicesPageSection/>
      <Footer/>
     
    </>
  )
}

export {ServicesPage}
export default {ServicesPage}