import React from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

import pomadaAlfa from "../assets/images/produtos/pomada-alfa.jpeg";
import pomadaAlfaForMen from "../assets/images/produtos/pomada-alfa-for-men.jpeg";
import pomadaAlfaSeco from "../assets/images/produtos/pomada-alfa-seco.jpeg";
import pomadaPo from "../assets/images/produtos/pomada-po.jpeg";
import shampooAlfa from "../assets/images/produtos/shampoo-alfa.jpeg";
import shampooBarba from "../assets/images/produtos/shampoo-barba.jpeg";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 82px 280px;
  background-color: #080808;
  color: #fefefe;
  border-top: 1px solid #fa951a;

  @media only screen and (max-width: 600px) {
    padding: 82px 16px;
    flex-direction: column;
    align-items: center;
  }
`;

const TitleProducts = styled.h3`
  font-size: 64px;
  margin: 0px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-family: Montserrat-extrabold;
`;

const Product = styled.img`
  width: 25%;
  height: 25%;
  border-radius: 20px;
  margin: 64px 16px;

  @media only screen and (max-width: 600px) {
    width: 80%;
    height: 80%;
    margin: 32px 16px;
  }
`;

const SeeMore = styled.a`
  all: unset;
  color: #fefefe;
  font-size: 18px;
  margin-top: 42px;
  cursor: pointer;
  width: 100%;
  text-align: center;

  transition: 200ms;

  :hover {
    color: #fa951a;
  }
`;

function Products() {
  AOS.init();

  return (
    <Container>
      <TitleProducts data-aos="fade-right">Nossos Produtos</TitleProducts>

      <Product data-aos="zoom-in-up" src={pomadaAlfa} />
      <Product data-aos="zoom-in-up" src={pomadaAlfaForMen} />
      <Product data-aos="zoom-in-up" src={pomadaAlfaSeco} />
      <Product data-aos="zoom-in-up" src={pomadaPo} />
      <Product data-aos="zoom-in-up" src={shampooAlfa} />
      <Product data-aos="zoom-in-up" src={shampooBarba} />

      <SeeMore data-aos="zoom-in-up" href="/produtos">
        Ver mais
      </SeeMore>
    </Container>
  );
}

export { Products };
export default Products;
