import React from "react"
import styled from "styled-components"

const Card = styled.div`
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  width: 360px;
  height: 122px;
  border-bottom: 5px solid #fa951a;
  border-radius: 0 0 25px 0;
  box-shadow: 0px -4px 5px 0px rgba(0,0,0,0.75);
  background-color: #242424;
  margin: 16px;
  transition: 200ms;

  :hover {
    width: 370px;
    height: 128px;
  }

  @media only screen and (min-width: 1600px) {
    width: 455px;
    height: 150px;

    :hover {
      width: 470px;
      height: 158px;
    }
  } 
`

const ImgContainer = styled.div`
  width: 35%;
  height: 90%;
  border-radius: 5px;
`

const CardImg = styled.img`
  width: 100%;
  height: 100%;
  margin: 8px;
  box-shadow: 0px 0px 35px -17px rgba(250,149,26,0.64);
`

const TextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width:60%;
`

const Title = styled.h6`
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 8px;

  @media only screen and (min-width: 1600px) {
    font-size: 16px
  } 
`

const Paragraf = styled.p`
  margin-top: 0;
  width: 95%;
  text-align: justify;
  font-size: 11px;

  @media only screen and (min-width: 1600px) {
    font-size: 13px;
  }
`

function ServiceCard (props) {

  return (
    <Card>
      <ImgContainer>
        <CardImg src={props.img}/>
      </ImgContainer>

      <TextContainer>
        <Title>
          {props.title}
        </Title>
        <Paragraf>
          {props.text}
        </Paragraf>

      </TextContainer>

    </Card>
  )

}

export {ServiceCard}
export default ServiceCard