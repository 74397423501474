import { useMediaQuery } from 'react-responsive'

const gridBreakpoints = {
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576,
  // Medium screen / tablet
  md: 768,
  // Large screen / desktop
  lg: 992,
  // Extra large screen / wide desktop
  xl: 1200
}

/** Hook - Auxilia na renderização condicional baseado no tamanho da tela.
 *
 * Este hook retorna variáveis binárias que sempre será apenas uma que estará como `true`. Esta definirá qual
 * o tamanho da tela atual (mobile/tablet/desktop/large). Ao ser redimensionado a tela essas variáveis são
 * automaticamente atualizadas.
 *
 * @returns Variáveis booleanas `isMobile`, `isTablet`, `isDesktop` e `isDesktopLarge` para definir
 * qual o tamanho da tela atualmente.
 */
const useTamanhoTela = () => {
  const isMobile = useMediaQuery({
    maxWidth: gridBreakpoints.md - 1
  })

  const isTablet = useMediaQuery({
    minWidth: gridBreakpoints.md,
    maxWidth: gridBreakpoints.lg - 1
  })

  const isDesktop = useMediaQuery({
    minWidth: gridBreakpoints.lg,
    maxWidth: gridBreakpoints.xl - 1
  })

  const isDesktopLarge = useMediaQuery({
    minWidth: gridBreakpoints.xl
  })

  return {
    isMobile,
    isTablet,
    isDesktop,
    isDesktopLarge
  }
}

export { useTamanhoTela }