import React from "react";
import styled from "styled-components";

// import { Nav } from '.'

import pomadaAlfa from "../assets/images/produtos/pomada-alfa.jpeg";
import pomadaAlfaForMen from "../assets/images/produtos/pomada-alfa-for-men.jpeg";
import pomadaAlfaSeco from "../assets/images/produtos/pomada-alfa-seco.jpeg";
import pomadaPo from "../assets/images/produtos/pomada-po.jpeg";
import shampooAlfa from "../assets/images/produtos/shampoo-alfa.jpeg";
import shampooBarba from "../assets/images/produtos/shampoo-barba.jpeg";
import oleoBarba from "../assets/images/produtos/oleo-barba.jpeg";
import hidratanteCapilar from "../assets/images/produtos/hidratante-capilar.jpeg";
import reparador from "../assets/images/produtos/reparador.jpeg";
import sprayCabelo from "../assets/images/produtos/spray-cabelo.jpeg";
import shampooMinoxidil from "../assets/images/produtos/shampoo-minoxidil.jpeg";
import shampooAntiCaspa from "../assets/images/produtos/shampoo-anti-caspa.jpeg";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 82px 280px;
  background-color: #080808;
  color: #fefefe;
  border-top: 1px solid #fa951a;

  @media only screen and (max-width: 600px) {
    padding: 82px 16px;
    flex-direction: column;
    align-items: center;
  }
`;

const TitleProducts = styled.h3`
  font-size: 64px;
  margin: 0px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-family: Montserrat-extrabold;
`;

const Product = styled.div`
  width: 25%;
  height: 25%;
  margin: 64px 16px;
  position: relative;
  overflow: hidden; /* Para garantir que a sobreposição não saia do contêiner */
  transition: 1s ease-in-out !important;

  @media only screen and (max-width: 600px) {
    width: 80%;
    height: 80%;
    margin: 32px 16px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover; /* Para garantir que a imagem preencha todo o contêiner */
    margin: 0;
    padding: 0;
  }
  p,
  h2,
  a {
    display: none;
  }

  /* Estilos da sobreposição escura e do texto no hover */
  :hover {
    ::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    p {
      display: block;
      width: 90%;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fa951a;
      font-size: 0.87rem;
      opacity: 0;
      transition: 0.5s ease-in-out;
    }
    h2 {
      display: block;
      width: 90%;
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fa951a;
      font-size: 0.87rem;
      opacity: 0;
      transition: 0.5s ease-in-out;
      text-align: center;
      font-family: Montserrat-ExtraBold;
      font-weight: 700;
    }
    a {
      all: unset;
      display: block;
      position: absolute;
      top: 87%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fa951a;
      opacity: 0;
      text-align: center;
      font-family: Montserrat-ExtraBold;
      font-weight: 700;
      border: 3px solid #fa951a;
      padding: 4px 16px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  :hover::before,
  :hover p {
    opacity: 1;
  }
  :hover h2 {
    opacity: 1;
  }
  :hover a {
    opacity: 1;
  }
`;

const produtos = [
  {
    titulo: "Pomada Black Alfa Look's 150g",
    texto:
      "Feita para para cabelos com fixação média, criada a base de água, o que significa a diminuição nos danos do seu cabelo.",
    imagem: pomadaAlfa,
  },
  {
    titulo: "Pomada Alfa Look's For Men 80g",
    texto:
      "Proporciona aos fios uma fixação média com brilho. Ideal para quem  procura penteados com uma “aparência molhada”. Possui uma fragrância incrível.",
    imagem: pomadaAlfaForMen,
  },
  {
    titulo: "Pomada Alfa Efeito Seco 60g",
    texto:
      "Com Efeito Seco Element é ideal para finalizar cabelos curtos e médios com acabamento matte, sem brilho com Fixação Extra Forte.",
    imagem: pomadaAlfaSeco,
  },
  {
    titulo: "Pó Powder White Alfa Looks",
    texto:
      "Proporciona um penteado com muito mais volume. Promove uma modelação versátil e suave, mantendo o movimento natural do cabelo.",
    imagem: pomadaPo,
  },
  {
    titulo: "Shampoo Anticaspa Alfa Look's",
    texto:
      "Feito para tratamento de infecções do couro cabeludo causadas pela Malassezia e por fungos causados pelo excesso de oleosidade.",
    imagem: shampooAlfa,
  },
  {
    titulo: "Shampoo para Barba Alfa Look's Prime",
    texto:
      "Possui pH neutro e ativos especiais que garantem uma limpeza suave sem prejudicar os fios ouressecar a pele.",
    imagem: shampooBarba,
  },
  {
    titulo: "Óleo para barba Mercado Barbeiro Signature 30ml",
    texto:
      "Promove hidratação e maciez. Barba alinhada para todos os tipos de barba",
    imagem: oleoBarba,
  },
  {
    titulo: "Linha Moisturizer",
    texto:
      "Composta por vitaminas, minerais e proteínas, promove a reposição e retenção hídrica aos cabelos secos e ressecados",
    imagem: hidratanteCapilar,
  },
  {
    titulo: "Linha TANINO THERAPY",
    texto:
      "Inspirada na força da natureza, foi desenvolvida para os profissionais que buscam versatilidade e resultados para o seu salão.",
    imagem: reparador,
  },
  {
    titulo: "Hair spray Evolution",
    texto:
      "Proporciona uma fixação de alta duração com aspecto natural, brilho e maciez aos fios.",
    imagem: sprayCabelo,
  },
  {
    titulo: "Shampoo Fortificante Capilar Dom Pelo",
    texto:
      "Estimula o crescimento, devolve a densidade e restaura o bulbo piloso, mantendo a saúde.",
    imagem: shampooMinoxidil,
  },
  {
    titulo: "Shampoo Anticaspa Dom Pelo",
    texto:
      "Conta com ativos naturais bem eficazes, proporcionando uma composição que elimina a caspa",
    imagem: shampooAntiCaspa,
  },
];

function ProductsPageSection() {
  return (
    <>
      <Container>
        <TitleProducts>Nossos Produtos</TitleProducts>
        {produtos.map((produto, index) => (
          <Product>
            <img src={produto.imagem} alt={produto.titulo} />{" "}
            <h2>{produto.titulo}</h2>
            <p>{produto.texto}</p>
            <a
              href="https://api.whatsapp.com/send?phone=+5584999886431&text=Olá,%20vim%20através%20do%20site%20e%20quero%20comprar%20um%20..."
              target="_blank"
            >
              Comprar
            </a>
          </Product>
        ))}
      </Container>
    </>
  );
}

export { ProductsPageSection };
export default ProductsPageSection;
