import React from "react";
import styled from "styled-components";

import instagramLogo from "../assets/images/instagram-logo-colorida.png";
import Whats from "../assets/images/logo_whats_colorida.png";
// import Face from '../assets/images/facebook-logo.png'
import DAVS from "../assets/images/davs.png";

const FooterStyled = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40%;
  background-color: #272424;
  padding-bottom: 24px;
  border-top: 5px solid #181616;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const FooterStyledDavs = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5%;
  background-color: #181616;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const FooterSection = styled.section`
  width: 25%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* padding: 64px; */

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    padding: 16px 0px;
    width: 100%;
  }
`;

const FooterSectionDavs = styled.section`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 32px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    padding: 16px 0px;
    width: 100%;
  }
`;

const SectionTitle = styled.h4`
  font-size: 22px;
  width: 100%;
  color: #fefefe;
  font-family: Montserrat-extrabold;
`;

const Link = styled.a`
  all: unset;
  color: #fefefe;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
`;

const Paragraf = styled.p`
  all: unset;
  color: #fefefe;
  font-size: 14px;
  width: 100%;
`;

const InstagramLogo = styled.img`
  height: 50px;
  width: 50px;
  margin: 0 8px;
`;

const WhatsIcon = styled.img`
  width: 45px;
  height: 45px;
  margin: 0 8px;
`;

const TextDavs = styled.a`
  font-size: 18px;
  color: #fa951a;
  cursor: pointer;
  text-decoration: none;
  display: flex:
  align-items: center;
  justify-content: center;
  transition: 300ms;

  :hover {
    color: #fefefe;
  }
`;

function Footer() {
  return (
    <>
      <FooterStyled>
        <FooterSection>
          <SectionTitle>Contato</SectionTitle>
          <Link href="tel:+558499886431">(84) 9988-6431</Link>
          <Link href="mailto:navalhadeouro@gmail.com">
            navalhadeouro@gmail.com
          </Link>
        </FooterSection>

        <FooterSection>
          <SectionTitle>Horário de funcionamento</SectionTitle>
          <Paragraf>Segunda - 13h às 19h30</Paragraf>
          <Paragraf>Terça à Sexta - 9h às 19h30</Paragraf>
          <Paragraf>Sábado - 8h às 19h30</Paragraf>
        </FooterSection>

        <FooterSection>
          <SectionTitle>Tags</SectionTitle>
          <Paragraf>Barbearia Coophab</Paragraf>
          <Paragraf>Barbearia em Parnamirim</Paragraf>
          <Paragraf>Barbearia em Natal</Paragraf>
          <Paragraf>Cortes de Cabelo</Paragraf>
          <Paragraf>Barba</Paragraf>
        </FooterSection>

        <FooterSection>
          <SectionTitle>Nossas redes sociais</SectionTitle>
          <Paragraf>
            <a
              href="https://www.instagram.com/navalhadeouro_/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramLogo src={instagramLogo} />
            </a>

            <a
              target="_blank"
              href="https://api.whatsapp.com/send?phone=+5584999886431&text=Olá,%20vim%20através%20do%20site%20e%20quero%20agendar%20um%20serviço..."
              rel="noreferrer"
            >
              <WhatsIcon src={Whats} />
            </a>
          </Paragraf>
        </FooterSection>
      </FooterStyled>

      <FooterStyledDavs>
        <FooterSectionDavs>
          <TextDavs
            href="https://api.whatsapp.com/send?phone=+5584998545520&text=Olá,%20gostaria%20de%20pedir%20um%20orçamento."
            target="__blank"
          >
            Desenvolvido por{" "}
            <img src={DAVS} width="32px" height="32px" alt="Logo DAVS" />
          </TextDavs>
        </FooterSectionDavs>
      </FooterStyledDavs>
    </>
  );
}

export { Footer };
export default Footer;
