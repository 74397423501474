export const textoCorteMasculino = "Além de valorizar os seus melhores traços, o corte de cabelo certo pode ajudar a disfarçar as imperfeições."
export const textoBarba = "Uma barba de respeito faz total diferença no seu estilo, a depender do estilo pode passar uma postura mais séria ou mesmo ousada."
export const textoPenteadoMasculino = "Seja para uma ocasião especial ou até para o cotidiano, o penteado pode ser o que faltava no visual."
export const textoFreestyle = "Freestyle é um corte em que o barbeiro faz desenhos livres na cabeça do cliente."
export const textoCabeloBarba = "Que tal unir o melhor dos dois mundos, aproveitando nosso combo?"
export const textoManicureMasculino = "Quem disse que manicura é coisa de mulher ? Cada vez mais os homens de estilo têm buscado esse serviço, você também pode."
export const textoCalosidades = "O melhor tratamento para acabar com calos e calosidades é eliminar a fonte de pressão que lhes dá origem. Este procedimento é indolor."
export const textoAlisamento = "O alisamento capilar pode ser exatamente aquilo que você estava procurando para renovar o visual por completo."
export const textoEscova = "Escovar o cabelo fará com que ele fique muito mais sedoso e macio."
export const textoPintura = "Os cabelos são a moldura do rosto, tingir os fios é uma alternativa para renovar a aparência e se sentir bem cuidada."
export const textoMechas = "Fazer mechas no cabelo é a escolha perfeita quando se trata de dar vida aos fios e acender o visual!"
export const textoCorteFeminino = "Cortar o cabelo faz bem para os fios, e ainda mais bem para a autoestima, podendo até se tornar fonte de motivação no seu dia-a-dia."
export const textoMegaHair = "O Megahair pode te proporcionar mais volume, um cabelo mais longo e até uma leve mudança de cor sem passar por produtos químicos."
export const textoMake = "O uso de maquiagem pode realçar e dar impressão de um brilho e beleza maior, trazendo beneficios até para a saúde mental."
export const textoManicure = "Muito além da beleza, manter as unhas limpas, saudáveis e bem cuidadas pode prevenir uma série de problemas."
export const textoMassagemRelax = "A massagem relaxante reduz a tensão muscular, alivia dores e estresse, melhorando a circulação sanguínea e promovendo bem-estar físico e mental."
export const textoLimpezaPele = "A limpeza de pele ajuda a remover impurezas, células mortas e oleosidade, prevenindo acnes e melhorando a aparência da pele, deixando-a mais saudável e suave."
export const textoDrenagem = "A drenagem linfática reduz inchaços, celulites e estimula o sistema linfático, eliminando toxinas e excesso de líquidos, promovendo bem-estar."
export const textoHidratacao = "A hidratação da pele previne o ressecamento, rugas e linhas de expressão, além de melhorar a textura e elasticidade da pele, deixando-a saudável e bonita."
export const textoDepilação = "Depilação pode melhorar a aparência, aumentar autoestima e facilitar a higiene pessoal. Além disso, pode ajudar a evitar pelos encravados e irritações na pele."
export const textoDesignSobrancelha = "O design de sobrancelhas destaca o rosto, molda o olhar e dá harmonia facial, resultando em uma aparência mais jovem e expressiva."
export const textoDesignSobrancelhaHena = "O design de sobrancelha com hena realça o olhar e preenche falhas de forma natural, durando mais tempo que a maquiagem convencional."

export const textoAboutUs1 = `Guto era um jovem soldado do Exército Brasileiro que, em 2014, foi enviado em uma missão de paz ao Haiti. Em meio aos conflitos e à rotina cansativa da missão, Guto descobriu que sabia cortar cabelo. Em um dia de folga, um colega de farda pediu que Guto cortasse seu cabelo e, para sua surpresa, o resultado ficou ótimo.`
export const textoAboutUs2 = `De volta ao Brasil em 2016, Guto decidiu abrir uma barbearia no bairro de Nova Parnamirim, na cidade de Natal. Com a ajuda de sua esposa Rose, a Navalha de Ouro foi fundada e logo se tornou um sucesso entre os moradores da região.`
export const textoAboutUs3 = `Guto e sua esposa continuaram aprimorando suas habilidades e expandindo o negócio ao longo dos anos.  Em 2023, durante uma grande reforma da barbearia que resultou na mudança do nome para Navalha Dourada, um novo espaço foi adicionado: o Espaço Mulher. Agora, além dos serviços de barbearia, a Navalha Dourada oferece também serviços de beleza e estética para as mulheres que frequentam o estabelecimento.`
export const textoAboutUs4 = `Encontre a Lions Barbearia no enderço abaixo:`