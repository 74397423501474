import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";

import MainImg from "../assets/images/main.png";
import Modal from "./Modal";
import InputForm from "./Input";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ImgContainer = styled.div`
  z-index: 1;
  background-size: cover;
  background-image: url(${MainImg});
  /* margin-top: 15%; */
  height: 60vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Container = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
`;

const MainText = styled.h1`
  all: unset;
  font-size: 64px;
  color: #fefefe;
  text-shadow: 3px 2px 3px rgba(255, 255, 255, 0.2),
    2px 2px 6px rgba(0, 0, 0, 1);
  /* text-transform: uppercase; */
  /* gap: 16px; */
  font-family: "Versa";

  @media only screen and (max-width: 600px) {
    font-size: 48px;
  }
`;

const SubText = styled.h2`
  all: unset;
  font-size: 26px;
  color: #fefefe;
  margin-bottom: 1%;

  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
`;

const MarkedText = styled.span`
  all: unset;
  background-color: #fa951a;
`;

const OrcamentoBtn = styled.a`
  all: unset;
  border: 5px solid #fa951a;
  padding: 8px 16px;
  color: #fa951a;
  font-size: 18px;
  cursor: pointer;
  transition: 200ms;

  /* text-transform: uppercase; */

  :hover {
    /* padding: 10px 18px;
    font-size: 22px; */
    background-color: #fa951a;
    color: #272424;
    border-color: #fa951a;
  }

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    padding: 4px 8px;
    border: 3px solid #fa951a;
  }

  @media only screen and (min-width: 1600px) {
    font-size: 22px;
  }
`;

function Main() {
  AOS.init();
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm();
  const customToastOptions = {
    position: "bottom-right", // Posição onde as notificações serão exibidas
    autoClose: 6000, // Tempo em milissegundos para as notificações fecharem automaticamente
    hideProgressBar: false, // Mostrar barra de progresso de tempo
    pauseOnHover: true, // Pausar o tempo de fechamento ao passar o mouse sobre a notificação
    draggable: true, // Permitir arrastar as notificações
    progress: undefined, // Componente customizado para barra de progresso, caso queira substituir
  };

  const enviarForm = (values) => {
    toast.warning("Enviando Formulário, Aguarde!", customToastOptions);
    const dados = {
      ...values,
    };

    axios
      .post("https://guto-functions.vercel.app/api", dados)
      .then(() => {
        toast.success("Formulário Enviado!", customToastOptions);
        closeModal();
        reset();
      })
      .catch((err) => {
        toast.error("Erro ao enviar formulário!", customToastOptions);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setModalOpen(true);
    }, 3000);
  }, []);
  return (
    <ImgContainer>
      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        title="Pré-inscrição curso Gutto Queiroz"
        onSave={handleSubmit(enviarForm)}
      >
        <InputForm
          id="Nome"
          type="text"
          label="Nome:"
          placeholder="Digite seu nome"
          register={register("nome", { required: "Este campo é obrigatório" })}
        />
        <InputForm
          id="Telefone"
          type="text"
          label="Telefone:"
          placeholder="Informe seu telefone"
          register={register("telefone", {
            required: "Este campo é obrigatório",
          })}
        />
        <InputForm
          id="Email"
          type="text"
          label="Email:"
          placeholder="Informe seu Email"
          campo="email"
          register={register("email", { required: "Este campo é obrigatório" })}
        />
      </Modal>

      <Container>
        <MainText data-aos="fade-right">
          Estilo, Cuidado, Praticidade...
        </MainText>
        <SubText data-aos="fade-left">
          Desfrute do <MarkedText>melhor</MarkedText> num ambiente aconchegante{" "}
          <MarkedText>próximo a você</MarkedText>!
        </SubText>

        <OrcamentoBtn
          href="https://api.whatsapp.com/send?phone=+5584999886431&text=Olá,%20vim%20através%20do%20site%20e%20quero%20saber%20a%20disponibilidade..."
          target="_blank"
        >
          Garanta sua vaga
        </OrcamentoBtn>
      </Container>
      <ToastContainer />
    </ImgContainer>
  );
}

export { Main };
export default { Main };
