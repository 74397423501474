import React from 'react'
import styled from 'styled-components'
import AOS from "aos"
import "aos/dist/aos.css"

import {textoAboutUs1, textoAboutUs2, textoAboutUs3, textoAboutUs4} from '../assets/texts'

import ImageAboutUs from '../assets/images/guto-e-rose.jpg'

const Container = styled.div`
  display: flex;
  /* width: 100%; */
  flex-wrap: wrap;
  padding: 82px 180px;
  background-color: #272424;
  color: #fefefe;

  @media only screen and (max-width: 600px) {
    padding: 82px 16px;
    flex-direction: column;
  }

  @media only screen and (min-width: 1600px) {
    padding: 82px 280px;
  }
  
`
const SubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 50%;

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-top: 28px;
  }
  
`

const TitleAboutUs = styled.h3`
  font-size: 64px;
  margin: 0px;
  text-transform: uppercase;
  text-align: center;
`

const Paragraf = styled.p`
  text-align: justify;
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 18px;

  @media only screen and (min-width: 1600px) {
    margin-bottom: 42px;
    line-height: 26px;
  }
`

const ImgAboutUs = styled.img`
  margin-left: 16px;
  width: 100%;

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-left: 0;
  }
`

function AboutUs (){
  AOS.init()

  return (
  <Container id="sobre">
    <SubContainer>
      <TitleAboutUs>Sobre nós</TitleAboutUs>
      <Paragraf data-aos="zoom-in-up">
        {textoAboutUs1}
      </Paragraf>
      <Paragraf data-aos="zoom-in-up">
        {textoAboutUs2}
      </Paragraf>
      <Paragraf data-aos="zoom-in-up">
        {textoAboutUs3}
      </Paragraf>
      <Paragraf data-aos="zoom-in-up">
        {textoAboutUs4}
      </Paragraf>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3968.51459332393!2d-35.21187528470709!3d-5.923553259929019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7b2f9028ff1c5a3%3A0xd82127376db4d616!2sNavalha%20de%20Ouro!5e0!3m2!1spt-BR!2sbr!4v1681213147310!5m2!1spt-BR!2sbr" width="100%" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </SubContainer>
    <SubContainer>
      <ImgAboutUs src={ImageAboutUs} alt='Foto da equipe na barbearia' data-aos="fade-left"/>
    </SubContainer>
  </Container>
  )

}

export {AboutUs}
export default AboutUs