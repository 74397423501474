import React from "react";
import styled from "styled-components";

import corteMasculino from "../assets/images/corte-masculino.jpg";
import barba from "../assets/images/barba.jpg";
import penteadoMasculino from "../assets/images/penteado.jpg";
import freestyle from "../assets/images/freestyle.jpg";
import cabeloBarba from "../assets/images/cabelo-e-barba.png";
import maleHand from "../assets/images/male-hand.jpg";
import feetCare from "../assets/images/feet-care.jpg";
import alisamento from "../assets/images/alisamento.jpg";
import escova from "../assets/images/escova.jpg";
import pintura from "../assets/images/pintura.jpg";
import mechas from "../assets/images/mechas.jpg";
import corteFeminino from "../assets/images/corte-feminino.jpg";
import megahair from "../assets/images/megahair.jpg";
import penteadoFeminino from "../assets/images/penteado-feminino.jpg";
import make from "../assets/images/make.jpg";
import manicure from "../assets/images/manicure.jpg";
import limpezaPele from "../assets/images/limpeza-de-pele.png";
import massagem from "../assets/images/massagem.png";
import drenagem from "../assets/images/drenagem.jpg";
import hidratacao from "../assets/images/hidratacao.jpg";
import depilacao from "../assets/images/depilacao.jpg";
import designSobrancelha from "../assets/images/design-sobrancelha.jpg";
import henna from "../assets/images/henna.jpg";

import {
  textoCorteMasculino,
  textoBarba,
  textoPenteadoMasculino,
  textoFreestyle,
  textoCabeloBarba,
  textoManicureMasculino,
  textoCalosidades,
  textoAlisamento,
  textoEscova,
  textoPintura,
  textoMechas,
  textoCorteFeminino,
  textoMegaHair,
  textoMake,
  textoManicure,
  textoMassagemRelax,
  textoDrenagem,
  textoLimpezaPele,
  textoHidratacao,
  textoDepilação,
  textoDesignSobrancelha,
  textoDesignSobrancelhaHena,
} from "../assets/texts";

import ServiceCard from "./ServiceCard";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: flex-start; */
  padding: 82px 280px;
  /* height: 100vh; */
  background-color: #181616;
  color: #fefefe;
  border-top: 1px solid #fa951a;

  @media only screen and (max-width: 600px) {
    padding: 82px 16px;
    flex-direction: column;
  }
`;

const TitleServices = styled.h1`
  font-size: 64px;
  margin: 0px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
`;

const SubTitle = styled.h2`
  font-size: 32px;
  margin-top: 84px;
  margin-bottom: 28px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-family: Montserrat-extrabold;
`;

const Back = styled.a`
  all: unset;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin: 64px;
  font-size: 22px;
`;

function ServicesPageSection() {
  return (
    <Container>
      <TitleServices>Serviços</TitleServices>

      <SubTitle>Masculino</SubTitle>

      <ServiceCard
        img={corteMasculino}
        title="Corte Masculino"
        text={textoCorteMasculino}
      />
      <ServiceCard img={barba} title="Barba" text={textoBarba} />
      <ServiceCard
        img={penteadoMasculino}
        title="Penteado Masculino"
        text={textoPenteadoMasculino}
      />
      <ServiceCard
        img={freestyle}
        title="Desenhos Freestyle"
        text={textoFreestyle}
      />
      <ServiceCard
        img={cabeloBarba}
        title="Combos de Cabelo e Barba"
        text={textoCabeloBarba}
      />
      <ServiceCard
        img={maleHand}
        title="Manicure Masculino"
        text={textoManicureMasculino}
      />
      <ServiceCard
        img={feetCare}
        title="Retirada de Calosidades"
        text={textoCalosidades}
      />

      <SubTitle>Feminino</SubTitle>

      <ServiceCard img={alisamento} title="Alisamento" text={textoAlisamento} />
      <ServiceCard img={escova} title="Escovaria Express" text={textoEscova} />
      <ServiceCard img={pintura} title="Pintura" text={textoPintura} />
      <ServiceCard img={mechas} title="Mechas" text={textoMechas} />
      <ServiceCard
        img={corteFeminino}
        title="Corte Feminino"
        text={textoCorteFeminino}
      />
      <ServiceCard img={megahair} title="Megahair" text={textoMegaHair} />
      <ServiceCard
        img={penteadoFeminino}
        title="Penteado feminino"
        text={textoCorteMasculino}
      />
      <ServiceCard img={make} title="Maquiagem" text={textoMake} />
      <ServiceCard img={manicure} title="Manicure" text={textoManicure} />
      <ServiceCard
        img={manicure}
        title="Sobrabcelha com Henna"
        text={textoManicure}
      />

      <SubTitle>Unissex</SubTitle>

      <ServiceCard
        img={limpezaPele}
        title="Limpeza de Pele"
        text={textoLimpezaPele}
      />
      <ServiceCard
        img={massagem}
        title="Massagem Manual Relaxante"
        text={textoMassagemRelax}
      />
      <ServiceCard
        img={drenagem}
        title="Drenagem linfática"
        text={textoDrenagem}
      />
      <ServiceCard
        img={hidratacao}
        title="Hidratação corporal"
        text={textoHidratacao}
      />
      <ServiceCard img={depilacao} title="Depilação" text={textoDepilação} />
      <ServiceCard
        img={designSobrancelha}
        title="Design de sobrancelha"
        text={textoDesignSobrancelha}
      />
      <ServiceCard
        img={henna}
        title="Sobrancelha com Hena"
        text={textoDesignSobrancelhaHena}
      />

      <Back href="/">Voltar</Back>
    </Container>
  );
}

export { ServicesPageSection };
export default ServicesPageSection;
