import React from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 8px;
  width: 80vh;
`;

const ModalHeader = styled.div`
  padding: 0 0.4rem;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
`;

const ModalBody = styled.div`
  padding: 1% 5%;
`;

const ModalFooter = styled.div`
  padding: 1rem;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
`;
const ButtonClose = styled.button`
  background: none;
  border: none;
  :hover {
    cursor: pointer;
  }
`;
const ButtonFechar = styled.button`
  padding: 1.5%;
  border-radius: 10%;
  margin: 2px;
  background: #848080a1;
  color: white;
  border: none;
  :hover {
    cursor: pointer;
  }
`;
const ButtonEnviar = styled.button`
  padding: 1.5%;
  border-radius: 10%;
  margin: 2px;
  background: green;
  color: white;
  border: none;
  :hover {
    cursor: pointer;
  }
`;

const Modal = ({ isOpen, onClose, title, children, onSave }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <h1>{title}</h1>
          <ButtonClose onClick={onClose}>X</ButtonClose>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <ButtonFechar onClick={onClose}>Fechar</ButtonFechar>
          <ButtonEnviar onClick={onSave}>Enviar</ButtonEnviar>
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
