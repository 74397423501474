import React from 'react'

import { 
  Nav,
  ProductsPageSection,
  Footer,

 } from '../components'

function ProductsPage(){
  return (
    <>
      <Nav/>
      <ProductsPageSection/>
      <Footer/>
     
    </>
  )
}

export {ProductsPage}
export default {ProductsPage}