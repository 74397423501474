import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

const FormGroup = styled.div`
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns || "1"}, 1fr);
  grid-gap: 10px;
`;

const Label = styled.label`
  font-weight: bold;
  text-align: left;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 14px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const InputForm = ({ label, type, id, placeholder, register }) => {
  return (
    <FormGroup>
      <Label htmlFor={id}>{label}</Label>
      <Input type={type} id={id} placeholder={placeholder} {...register} />
    </FormGroup>
  );
};

export default InputForm;
